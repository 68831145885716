



















import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

@Component({
  components: {
    AppLanguage: () => import("@/app/modules/settings/components/AppLanguage.vue"),
    PushNotifications: () => import("@/app/modules/settings/components/PushNotifications.vue")
  }
})
export default class SettingsPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("Settings");
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }
}
